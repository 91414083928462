import React, { useContext } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../../components/Layout"
import { Container, Col, Row } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faShieldAlt,
  faPencilRuler,
  faLeaf,
  faConciergeBell,
  faSyncAlt,
  faToolbox,
} from "@fortawesome/free-solid-svg-icons"
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons"
import { Banner } from "../../components/Banner"
import styled from "styled-components"
import EducationOffers from "../../components/education/EducationOffers"
import Img from "gatsby-image"
import { myContext } from "../../ThemeContext"
import SEO from "../../components/seo"
import IconBoxList from "../../components/BoxList"
import SectionTitle from "../../components/SectionTitle"

export default function Home() {
  const images = useStaticQuery(graphql`
    query educationAbout {
      BannerImage: file(
        relativePath: { eq: "education/AdobeStock_347784156.jpeg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 250) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      HowItWorks1: file(
        relativePath: { eq: "education/AdobeStock_44214098.jpeg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      HowItWorks2: file(
        relativePath: { eq: "education/AdobeStock_235889550.jpeg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      HowItWorks3: file(
        relativePath: { eq: "education/AdobeStock_337982191.jpeg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      HowItWorks4: file(
        relativePath: { eq: "education/AdobeStock_140022732.jpeg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout headerType="education">
      <SEO title="Education Leasing · Compulease" />
      <EducationOffers />
      <AboutUs />
      <Support />
      <OffsetBanner>
        <Container>
          <Banner
            image={images.BannerImage.childImageSharp.fluid}
            title="Maintenance Agreement"
            description="Compulease™ offers two distinct support packages which
          provide you with varying levels of support depending on your
          situation. The Elite maintenance package provides dedicated
          support and is effectively like having your own IT Manager,
          but at a fraction of the cost."
            link={{
              text: "Get in touch",
              to: "/education/ContactUs/",
            }}
          />
        </Container>
      </OffsetBanner>
      <HowItWorks
        HowItWorks1={images.HowItWorks1.childImageSharp.fluid}
        HowItWorks2={images.HowItWorks2.childImageSharp.fluid}
        HowItWorks3={images.HowItWorks3.childImageSharp.fluid}
        HowItWorks4={images.HowItWorks4.childImageSharp.fluid}
      />
      <WhyChoose />
    </Layout>
  )
}

function AboutUs() {
  const theme = useContext(myContext)
  return (
    <AboutUsSection theme={theme}>
      <Container>
        <Row>
          <Col>
            <SectionTitle
              title="Laptop Leasing and Computer Finance Solutions for UK
              Schools, Colleges and Universities"
              description={
                <>
                  <p>
                    Welcome to the Compulease™ Education website dedicated to
                    high quality leasing for school laptops and computers, IT
                    solutions and other equipment for all areas of education.
                  </p>
                  <p>
                    You can be confident, with our unique approach to design,
                    implementation and Customer Services that you will be
                    delivered an education finance solution which provides you
                    with “complete peace of mind” in the most cost efficient
                    way.
                  </p>
                  <p>
                    The Compulease™ program will enable you to keep your
                    teaching establishment up-to-date with the latest technology
                    as it develops and with our “guaranteed protection against
                    equipment obsolescence” program, your teaching environment
                    may never have to “make do” with old technology again.
                  </p>
                  <Row>
                    <Col xs="12" md="6" className="list">
                      <ul>
                        <li>
                          <FontAwesomeIcon icon={faConciergeBell} />
                          <span>Friendly Customer Service Team</span>
                        </li>
                        <li>
                          <FontAwesomeIcon icon={faShieldAlt} />
                          <span>Obsolescence Protection</span>
                        </li>
                        <li>
                          <FontAwesomeIcon icon={faShieldAlt} />
                          <span>Warranty</span>
                        </li>
                      </ul>
                    </Col>
                    <Col xs="12" md="6" className="list">
                      <ul>
                        <li>
                          <FontAwesomeIcon icon={faShieldAlt} />
                          <span>Equipment Protection Cover</span>
                        </li>
                        <li>
                          <FontAwesomeIcon icon={faLeaf} />
                          <span>Eco-friendly Asset Disposal</span>
                        </li>
                        <li>
                          <FontAwesomeIcon icon={faSyncAlt} />
                          <span>Technology Refresh</span>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </>
              }
            />
          </Col>
        </Row>
      </Container>
    </AboutUsSection>
  )
}

function Support() {
  const theme = useContext(myContext)

  return (
    <SupportSection theme={theme}>
      <Container>
        <Row>
          <Col md="12">
            <SectionTitle
              title="The level of support depends on your individual requirements"
              subtitle="Support for Schools and Colleges"
              description="Supplying the “right equipment at the right price” is only part of
              the service available to our education finance clients, and
              Compulease™ prides itself in being able to deliver a complete IT
              leasing solution, enabling teachers to focus on teaching and not
              having their valuable time taken up project managing IT
              procurement and installation."
            ></SectionTitle>
          </Col>
        </Row>
        <IconBoxList
          boxSizeLarge
          items={[
            {
              title: "Warranty",
              desc:
                "All hardware is covered by manufacturer’s warranties and in most cases these can be enhanced even further. Most computers and laptops are supplied as standard with a 3 year next business day onsite warranty to reduce any potential downtime to a minimum.",
              icon: <FontAwesomeIcon icon={faShieldAlt} color="white" />,
            },
            {
              title: "Installation",
              desc:
                "Our installation services include: Install to desk & imaging, Data migration, Server installation & configuration, Storage & charging solutions, Network cabling & electrical work, AV installation, Bespoke furniture installation",
              icon: <FontAwesomeIcon icon={faPencilRuler} color="white" />,
            },
            {
              title: "Equipment Protection Cover (EPC)",
              desc:
                "Compulease™ can provide low cost comprehensive EPC to provide that additional peace of mind. This includes: Cover for theft, accidental damage, fire & flood with no excess* and no loss adjusters. Rapid claims response *Excess applied to laptops, iPads and outdoor screens only",
              icon: <FontAwesomeIcon icon={faToolbox} color="white" />,
            },
            {
              title: "Asset Disposal",
              desc:
                "IT asset disposal enables you to discard old IT equipment rapidly and legally. We ensure complete compliance with current and future technology disposal regulations. We also help you maximise your financial return on discarded equipment. All fully-certifiable and ISO-accredited, ISC solutions range from environmental disposal to asset-managed remarketing and secure data purging. We offer comprehensive advice and management on all available options and provide full licensing support.",
              icon: <FontAwesomeIcon icon={faLeaf} color="white" />,
            },
          ]}
        />
      </Container>
    </SupportSection>
  )
}

function HowItWorks({ HowItWorks1, HowItWorks2, HowItWorks3, HowItWorks4 }) {
  const theme = useContext(myContext)

  return (
    <HowItWorksComponent>
      <Container>
        <Row>
          <Col>
            <SectionTitle subtitle="How it works" title="We keep it simple" />
          </Col>
        </Row>
        <Row>
          <Col>
            <ProcessBox theme={theme}>
              <div className="box-image">
                <Img fluid={HowItWorks1} className="img-fluid" alt="Select" />
                <div className="process-num">
                  <span className="number">1</span>
                </div>
              </div>
              <div className="featured-content">
                <h5>Select</h5>
                <p>Agree on the right solution for you</p>
              </div>
            </ProcessBox>
          </Col>
          <Col>
            <ProcessBox theme={theme}>
              <div className="box-image">
                <Img fluid={HowItWorks2} className="img-fluid" alt="Sign" />
                <div className="process-num">
                  <span className="number">2</span>
                </div>
              </div>
              <div className="featured-content">
                <h5>Sign</h5>
                <p>Sign your lease agreement</p>
              </div>
            </ProcessBox>
          </Col>
          <Col>
            <ProcessBox theme={theme}>
              <div className="box-image">
                <Img fluid={HowItWorks3} className="img-fluid" alt="Receive" />
                <div className="process-num">
                  <span className="number">3</span>
                </div>
              </div>
              <div className="featured-content">
                <h5>Receive</h5>
                <p>Have your goods delivered & installed</p>
              </div>
            </ProcessBox>
          </Col>
          <Col>
            <ProcessBox theme={theme}>
              <div className="box-image">
                <Img fluid={HowItWorks4} className="img-fluid" alt="Refresh" />
                <div className="process-num">
                  <span className="number">4</span>
                </div>
              </div>
              <div className="featured-content">
                <h5>Refresh</h5>
                <p>
                  Keep your equipment up to date with our Technology Refresh
                  program
                </p>
              </div>
            </ProcessBox>
          </Col>
        </Row>
      </Container>
    </HowItWorksComponent>
  )
}

function WhyChoose() {
  function ListItem(props) {
    return (
      <li>
        <FontAwesomeIcon icon={faCheckCircle} color="#2d4a8a" size="sm" />
        <span>{props.children}</span>
      </li>
    )
  }

  return (
    <Container>
      <Row>
        <Col>
          <SectionTitle
            title="The Compulease™ Commitment to Education Service"
            description="You will notice the difference in the Compulease™ service from the
              very first time you contact us, where the attention to detail we
              place on your requirements is paramount. When you procure from
              Compulease™ you are not on your own. You can be assured in the
              knowledge that a fully trained and qualified team will be
              allocated to help you manage your projects, keep within budget and
              deliver a “value for money” solution."
          />
          <ReasonsWhy>
            <Row>
              <Col lg={{ span: 8, offset: 2 }}>
                <h4>10 reasons why education clients choose Compulease</h4>
                <ul>
                  <ListItem>
                    Have the equipment you need, when you need it
                  </ListItem>
                  <ListItem>
                    If you have a capital budget set for the next three years,
                    it is only possible to purchase that amount of equipment per
                    year. By using Compulease™ you can get all of the equipment
                    you need right now
                  </ListItem>
                  <ListItem>
                    Protect you against equipment obsolescence
                  </ListItem>
                  <ListItem>
                    You can update or add to your existing equipment at any time
                    to ensure you have the very latest teaching facilities
                  </ListItem>
                  <ListItem>
                    Keep your teaching environment in continuous touch with
                    technology as it develops
                  </ListItem>
                  <ListItem>
                    Continuously revise your IT equipment to ensure your school
                    keeps up to date with the very latest technology
                  </ListItem>
                  <ListItem>
                    Retain your old equipment – free of charge
                  </ListItem>
                  <ListItem>
                    As Compulease™ updates your IT equipment, you may retain the
                    original equipment, free of charge. Alternatively, and at
                    your request, you may take advantage of the Compulease™
                    asset disposal service
                  </ListItem>
                  <ListItem>
                    Deferred Payments. You can start getting the benefits of
                    using the new equipment right away and not have to pay for
                    it until your budget becomes available
                  </ListItem>
                  <ListItem>
                    Your agreement can include hardware, software, installation
                    and even training
                  </ListItem>
                </ul>
                <div className="call-now">
                  <span>
                    For all enquiries call us now on{" "}
                    <a href="tel:+441625531777">01625 531 777</a> or email us at{" "}
                    <a href="mailto:info@compulease.co.uk">
                      info@compulease.co.uk
                    </a>
                  </span>
                </div>
              </Col>
            </Row>
          </ReasonsWhy>
        </Col>
      </Row>
    </Container>
  )
}

const AboutUsSection = styled.div`
  text-align: center;
  ul {
    margin-top: 1rem;
    color: #0e2b89;
    list-style: none;
    padding: 0;
    padding-left: 0;
    font-size: 18px;
    text-align: left;
    li {
      margin-bottom: 0.75rem;
      svg {
        max-width: 18px;
        max-height: 18px;
      }
    }
    span {
      color: #263045;
      padding-left: 1rem;
    }
  }
  @media (min-width: 768px) {
    .list {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`

const SupportSection = styled.section`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: ${props => props.theme.grey};
  padding: 5rem 0 6rem 0;
  position: relative;
  display: block;
  clear: both;
  content: "";
`

const OffsetBanner = styled.div`
  margin-top: -95px;
`

const ProcessBox = styled.div`
  margin-bottom: 1rem;
  padding: 0 15px;
  position: relative;
  text-align: center;
  &:last-child .box-image:before {
    content: unset;
  }
  .box-image {
    border: 3px solid #f0f0f0;
    border-radius: 50%;
    margin-bottom: 35px;
    position: relative;
    display: inline-block;
    transition: all 0.4s ease-in-out;
    img {
      border: 10px solid white;
      box-shadow: 0 0 9px 0;
      border-radius: 50%;
      transition: all 0.4s ease-in-out;
      height: 185px;
      width: 185px;
    }
    &::before {
      display: block;
      content: "";
      position: absolute;
      right: -95px;
      top: 45%;
      height: 22px;
      width: 72px;
      background-repeat: no-repeat;
    }
  }
  .process-num {
    margin-right: 5px;
    font-size: 16px;
    font-weight: 500;
    background-color: ${props => props.theme.primaryBlue};
    color: white;
    width: 40px;
    height: 40px;
    line-height: 41px;
    border-radius: 50%;
    border: none;
    display: block;
    text-align: center;
    position: absolute;
    left: 50%;
    margin-left: -20px;
    bottom: -15px;
    z-index: 10;
    transition: all 0.2s ease-in-out;
  }
  .featured-content {
    text-align: center;
    padding: 0 15px;
  }
  h5 {
    margin-bottom: 10px;
  }
  .processBox:hover {
    .box-image {
      border-color: ${props => props.theme.primaryBlue};
    }
    .process-num {
      background-color: ${props => props.theme.primaryBlue};
    }
  }
`

const HowItWorksComponent = styled.div`
  margin-bottom: 4rem;
  .img-fluid {
    box-shadow: 0 0 9px 0 rgba(14, 43, 137, 0.09);
    border-radius: 50%;
    transition: all 0.4s ease-in-out;
    height: 185px;
    width: 185px;
  }
`

const ReasonsWhy = styled.div`
  h4,
  span {
    text-align: center;
  }
  ul {
    list-style: none;
    padding: 0;
    padding-left: 0;
    li {
      font-size: 16px;
      padding-left: 22px;
      list-style: none;
      position: relative;
      padding-bottom: 10px;
      svg {
        position: absolute;
        left: 0px;
        top: 5px;
      }
    }
  }
  .call-now {
    margin-bottom: 2rem;
    text-align: center;
  }
`
